import axiosApi from '@/api/axiosApi'

const list = (data) => {
    return axiosApi({
        method: 'POST',
        url: `/intserv/certification/list`,
        data
    })
}

export default {
    list
}