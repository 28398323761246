<template>
  <div>
    <div class="banner">
        <div class="banner_titile">
            <span>HOLOWITS Support</span>
        </div>
        <div class="t-nav">
            <div v-for="(item, index) in links" :key="item.name" class="target" @click="toPage1(item.path)" @mouseenter="active(item)" @mouseout="unactive(item)">
                <div class="icon">
                    <img :src="item.icon" alt="" :style="{'display': index !== 0 ? 'block': 'none'}"/>
                    <img :src="item.activeIcon" alt="" :style="{'display': index === 0 ? 'block': 'none'}"/>
                </div>
                <div class="name" :style="{'color': index === 0 ? 'rgb(199, 0, 11)': ''}">{{ item.name }} <span v-if="index === 0" class="beta">New</span></div>
                <div v-if="index === 0" class="line"></div>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="title">
            <h1>EU Declaration of Conformity</h1>
        </div>
        <hr/>
        <div class="search-bar">
            <i class="el-icon-search search-icon"/>
            <input class="search-input" type="text" placeholder="Enter Model" v-model="form.model" @keyup.enter="search">
            <div class="search-button" @click="search">{{ $i18n.t('news.search') }}</div>
        </div>
        <p class="tips">If any stakeholder requests the EU declaration of conformity in other official language, as determined by the Member States concerned, we will provide it as soon as possible. If any question, please contact support@holowits.com</p>
        <div class="doc-result" v-if="total">
            <span class="doc-result-text">
                {{ $i18n.t('common.RefineBy') }}（{{ total }} {{ $i18n.t('documentation.result') }}）
            </span>
            <!-- <div>
                <el-select size="small" v-model="pageParam.sort" @change="getList">
                    <el-option value="publishDate" :label="$i18n.t('documentation.publishDate')"/>
                    <el-option value="downloadTimes" :label="$i18n.t('documentation.downloadTimes')"/>
                </el-select>
            </div> -->
        </div>

        <div style="margin-bottom: 20px;" v-if="total">
            <table>
                <thead>
                    <tr>
                        <th style="width: 10%;">Certification</th>
                        <th style="width: 25%;">Model</th>
                        <th style="width: 25%;">Document</th>
                        <th style="width: 13%;">Language</th>
                        <th style="width: 13%;">Publication Date</th>
                        <th style="width: 13%;">Operation</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in results" :key="index">
                        <td>{{ item.certification }}</td>
                        <td>{{ item.model }}</td>
                        <td>{{ item.document }}</td>
                        <td>{{ item.language }}</td>
                        <td>{{ item.createTime | datefilter}}</td>
                        <td>
                            <el-button type="text" icon="el-icon-download" @click="download(item.fileUrl)">Download</el-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
import CertificationApi from '@/api/certificationApi'
export default {
name: '',
data() {
    return {
        form: {
            model: ''
        },
        total: 0,
        results: [],
        links: [
            {
                icon: 'https://resources.holowits.com/support/申请单.png',
                activeIcon: 'https://resources.holowits.com/support/申请单.png',
                // activeIcon: 'https://resources.holowits.com/support/wiki_red.png',
                name: 'Wiki',
                path: '/holowitswiki',
                active: false
            },
            {
                icon: 'https://resources.holowits.com/support/firmware.png',
                // activeIcon: 'https://resources.holowits.com/support/firmware.png',
                activeIcon: 'https://resources.holowits.com/support/firmware_red.png',
                name: 'Firmware',
                path: '/firmware',
                active: false
            },
            {
                icon: 'https://resources.holowits.com/support/software.png',
                // activeIcon: 'https://resources.holowits.com/support/software.png',
                activeIcon: 'https://resources.holowits.com/support/software_red.png',
                name: 'Software',
                path: '/soft-download',
                active: false
            },
            {
                icon: 'https://resources.holowits.com/support/knowledge.png',
                // activeIcon: 'https://resources.holowits.com/support/knowledge.png',
                activeIcon: 'https://resources.holowits.com/support/knowledge_red.png',
                name: 'Knowledge',
                path: '/knowledge',
                active: false
            },
            {
                icon: 'https://resources.holowits.com/support/e-learning.png',
                // activeIcon: 'https://resources.holowits.com/support/e-learning.png',
                activeIcon: 'https://resources.holowits.com/support/e-learning_red.png',
                name: 'E-learning',
                path: '/training',
                active: false
            },
            {
                icon: 'https://resources.holowits.com/support/policy_4.png',
                // activeIcon: 'https://resources.holowits.com/support/policy_4.png',
                activeIcon: 'https://resources.holowits.com/support/terms_red.png',
                name: 'Policy,Terms',
                path: '/terms',
                active: false
            },
        ],
    }
},
filters: {
    datefilter(val) {
        return val.split(' ')[0]
    }
},
mounted() {
    // this.search()
},
methods: {
    async download(url) {
        // fetch(url, {
        //     method: 'GET',
        //     mode: 'cors'
        // }).then(res => {
        //     console.log(res)
        // })
        window.open(url)
    },
    async search() {
        const {data: {data, isSuccess}} = await CertificationApi.list({
            model: this.form.model,
            status: 'online'
        })
        if (isSuccess) {
            console.log(data)
            this.tableData = data
        }
    },
    toPage1(path) {
        if (path.includes('/holowitswiki')) {
            if (!this.$store.state.client.user.id) {
                this.$router.push({path: '/login'})
                return false;
            }
        }
        if (path) {
            this.$router.push({path: path})
        }
    },
    active(item) {
        item.active = true
    },
    unactive(item) {
        item.active = false
    },
    async search() {
        const {data: {data, isSuccess}} = await CertificationApi.list({
            model: this.form.model,
            status: this.form.status
        })
        if (isSuccess) {
            this.results = data
            this.total = data.length
        }
    },
}
}
</script>

<style lang="scss" scoped>
.target {
        min-width: 80px;
        height: 100%;
        cursor: pointer;
        &:hover {
            .icon {
                width: 70px;
                height: 70px;
                img:nth-child(1) {
                    display: none !important;
                }
                img:nth-child(2) {
                    display: block !important;
                }
            }
            .name {
                color: rgb(199, 0, 11);
            }
        }

        &:nth-child(1) {
            position: relative;
            .icon {
                width: 70px;
                height: 70px;
                img:nth-child(1) {
                    display: none !important;
                }
                img:nth-child(2) {
                    display: block !important;
                }
            }
            .name {
                color: rgb(199, 0, 11);
                display: flex;
                align-items: center;
                justify-content: space-between;
                .beta {
                    margin-left: 5px;
                    background-color: rgba(255, 174, 0, 0.774);
                    color: #000000;
                    font-size: 12px;
                    border-radius: 5px;
                    padding: 2px 5px;
                }
            }
            .line {
                height: 5px;
                width: 100%;
                background-color: rgb(197, 0, 11);
                position: absolute;
                bottom: 1px;
            }
        }

        .icon {
            transition: all 0.2s;
            width: 60px;
            height: 60px;
            margin: 0 auto;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .name {
            text-align: center;
            font-size: 18px;
        }
    }
    .target+.target {
        margin-left: 90px;
    }
  .banner {
    height: 420px;
    background-image: url('https://resources.holowits.com/support/banner_2.png');
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    color: #FFFFFF;
    position: relative;
    
        .banner_titile {
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
                font-size: 42px;
                text-shadow: 2px 2px 9px #00000099;
            }
        }
    }
    .t-nav {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 120px;
        background-color: #00000075;

        .target {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }

    .content {
        width: 1200px;
        margin: 0 auto;
        .title {
            text-align: center;
            margin: 70px 0;
        }

        .search-bar {
            margin: 40px auto;
            width: 80%;
            max-width: 750px;
            background-color: #FFFFFF;
            height: 50px;
            border-radius: 48px;
            display: flex;
            align-items: center;
            overflow: hidden;
            position: relative;
            z-index: 2;
            box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.08);

            .search-icon {
                font-size: 24px;
                margin: 0 12px 0 24px;
                color: #999999;
                position: relative;
                z-index: 10;
            }

            .search-input {
                flex: 1;
                width: 100%;
                height: 50px;
                box-shadow: none;
                outline: none;
                font-size: 18px;
                font-family: "Microsoft Yahei", sans-serif;
                padding-left: 60px;
                margin-left: -55px;
                border-radius: 48px 0 0 48px;
                border: 1px solid transparent;
                position: relative;
                z-index: 3;
                color: #333333;

                &:focus + .search-icon {
                    color: #c7000b;
                }
            }

            .search-button {
                cursor: pointer;
                color: #FFFFFF;
                font-size: 16px;
                background-color: var(--themeColor);
                height: 100%;
                width: 80px;
                padding-right: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                user-select: none;

                &:active {
                    opacity: .8;
                }
            }
        }

        .tips {
            font-size: 18px;
            line-height: 36px;
            margin-bottom: 50px;
        }

        .doc-result {
            display: flex;
            align-items: baseline;
            padding: 20px 0px 10px 0px;
            justify-content: space-between;
            border-bottom: 1px solid #F2F2F2;

            .doc-result-text {
                font-size: 15px;
                font-weight: bold;
                color: #000000;
            }

            .el-select {
                width: 200px;
            }
        }
    }
    table {
        width: 100%;
        // margin-top: 8px;
        thead {
            height: 36px;
            background-color: #f0f0f0;
            th {
                padding: 0 10px;
                text-align: center;
            }
        }
        tbody {
            tr {
                border-bottom: 1px solid rgba(128, 128, 128, 0.219);
                height: 36px;
                td {
                    padding: 10px;
                    text-align: center;
                }
                .t-link {
                    border-bottom: 1px solid transparent;
                    cursor: pointer;
                    &:hover {
                        color: #c7000b;
                        border-bottom: 1px solid #c7000b;
                    }
                }
            }
        }
    }
</style>